import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api/api.service';
import { HttpClient } from '@angular/common/http';
import { User } from '@core/states/auth/interfaces/user';
import { environment } from '@environment/environment';

@Injectable({ providedIn: 'root' })
export class UsersApi extends ApiService<User> {
  override apiUrl = environment.apiConfUrl;
  override endpoint = 'users';

  constructor(http: HttpClient) {
    super(http);
  }
}
